<template>
  <Datatable
    :total="getStocks.meta.page.total"
    :perPage="getStocks.meta.page.perPage"
    :currentPage="getStocks.meta.page.currentPage"
    id="daftar_stok"
    @pagechanged="onPageChange"
  >
    <template v-slot:thead>
      <thead
        :class="[
          bgColor(userOfficeCategory.attributes.name),
          textColor(userOfficeCategory.attributes.name),
        ]"
      >
        <tr>
          <th
            scope="col"
            v-for="{ label, classes } in ScStockColumns({
              withCode: false,
              withAreaZeroPrice: withAreaZeroPrice,
            })"
            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
            :key="label"
            :class="classes"
          >
            {{ label }}
          </th>
        </tr>
      </thead>
    </template>
    <template v-slot:tbody>
      <tbody v-if="!getStocks.data.length">
        <tr class="bg-white">
          <td
            colspan="9"
            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
          >
            Data Tidak Ditemukan
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="getStocks.data.length">
        <tr
          class="cursor-pointer bg-white hover:bg-blue-100"
          v-for="(data, dataIdx) in getStocks.data"
          :key="data.id"
          :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
          @click="onDetail(data)"
        >
          <td
            class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
          >
            {{ data.attributes.product_code }}
          </td>
          <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
            {{ data.attributes.product_name }}
          </td>
          <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
            {{ data.attributes.is_point ? 'P' : 'N' }}
          </td>
          <td
            v-if="withAreaZeroPrice"
            class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
          >
            {{ getAreaZeroPrice(data).attributes.price | toCurrency }}
          </td>
          <td
            class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
          >
            {{ data.attributes.stock_qty | toCurrency }}
          </td>
          <td
            class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
          >
            {{ data.attributes.free_qty | toCurrency }}
          </td>
          <td
            class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
          >
            {{ data.attributes.stock_ordered | toCurrency }}
          </td>
          <td
            class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
          >
            {{ data.attributes.free_ordered | toCurrency }}
          </td>
          <td
            class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
          >
            {{ data.attributes.stock_real | toCurrency }}
          </td>
          <td
            class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
          >
            {{ data.attributes.free_real | toCurrency }}
          </td>
        </tr>
      </tbody>
    </template>
  </Datatable>
</template>
<script>
import { ScStockColumns } from '@/services/columns.service';
import Datatable from '@/components/base/Datatable';
import { mapGetters } from 'vuex';
import { bgColor, textColor } from '@/utils/themes';

export default {
  name: 'StockTable',
  props: {
    withAreaZeroPrice: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Datatable,
  },
  computed: {
    ...mapGetters({
      getStocks: 'stocks/getStocks',
      userOfficeCategory: 'auth/getUserOfficeCategory',
      me: 'auth/getUser',
      getUser: 'auth/getUser',
    }),
  },
  methods: {
    bgColor,
    textColor,
    ScStockColumns,
    onPageChange(page) {
      this.$emit('page-changed', page);
    },
    onDetail(data) {
      this.$emit('detail', data);
    },
    getAreaZeroPrice(data) {
      const product = this.getSingleIncluded(this.getStocks, data.relationships.product.data.id)
      const productPrices = this.getIncludedByType(this.getStocks, 'product-prices', product)

      return productPrices.find(productPrice => {
        return productPrice.attributes.area === 'Wilayah Jawa' && productPrice.attributes['buyer-type'] === 'Stockist'
      })
    },
  },
};
</script>
