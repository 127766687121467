<template>
  <div :id="id" class="overflow-hidden rounded-lg bg-white shadow" @click="$emit('click')">
    <div class="relative p-4">
      <div class="flex items-center">
        <div class="w-0 flex-1">
          <div>
            <div class="flex items-center justify-between md:block lg:flex">
              <div class="truncate text-sm font-medium text-gray-900">
                {{ label }}
              </div>
            </div>
            <div class="mt-6">
              <div class="text-lg font-bold text-gray-900" :id="`${id}-value`">
                {{ value | toCurrency }}
              </div>
            </div>
          </div>
        </div>
        <div class="absolute -bottom-4 -right-3 flex-shrink-0 text-gray-200">
          <!-- Heroicon name: outline/scale -->
          <svg xmlns="http://www.w3.org/2000/svg" class="h-16 w-16" viewBox="0 0 20 20" fill="currentColor">
            <path d="M5.5 16a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 16h-8z" />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: null,
      required: true
    },
    id: String
  },
  emits: ['click']
}
</script>